import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AtlasAuthorityUnreachableError, IAuthService, isConnectionError } from '../../../lib/index';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { GenericViewProps } from '../../GenericViewProps';
import { Layout, LayoutContent, LayoutSidebar } from '../../Layout';

type LoginPromptViewProps = GenericViewProps & {
  authService: IAuthService;
  redirectAfterLogin: unknown;
}

type LoginPromptProps = GenericViewProps & {
  title: string;
  description: string;
  onButtonClicked: () => void;
  error: Error | null;
}

export function LoginPromptView(props: LoginPromptViewProps): JSX.Element | null {
  const history = useHistory();
  const { t } = useTranslation();

  const [
    restoreUserPending,
    setRestoreUserPending,
  ] = useState(true);

  const [
    loginError,
    setLoginError,
  ] = useState<Error | null>(null);

  useEffect(() => {
    props.authService.detectLoggedInFlag()
      .then((isLoggedIn) => {
        if (!restoreUserPending) {
          return;
        }
        setRestoreUserPending(false);

        if (isLoggedIn) {
          history.replace('/');
        } else {
          doLogin();
        }
      });
  });

  const doLogin = async (): Promise<void> => {
    setLoginError(null);
    try {
      await props.authService.login(props.redirectAfterLogin);
    } catch (err) {
      if (isConnectionError(err)) {
        setLoginError(new AtlasAuthorityUnreachableError(err.code));
      } else {
        setLoginError(err);
      }
    }
  };

  if (restoreUserPending) {
    return <LoginPrompt
      {...props}
      title={t('Auth.LoginInProgress')}
      description={t('Auth.LoadingUserInformation')}
      onButtonClicked={doLogin}
      error={loginError}
    />;
  }

  if (props.authService.isLoggedIn()) {
    return <Layout>
      <LayoutSidebar visible={props.sidebarVisible} hideSidebar={props.hideSidebar} logo={props.logo} />
      <LayoutContent />
    </Layout>;
  }

  return <LoginPrompt
    {...props}
    title={t('Auth.RedirectionInProgress')}
    description={t('Auth.LoginNecessary')}
    onButtonClicked={doLogin}
    error={loginError}/>;
}

function LoginPrompt(props: LoginPromptProps): JSX.Element {
  const { t } = useTranslation();

  return (<Layout>
    <LayoutSidebar visible={props.sidebarVisible} hideSidebar={props.hideSidebar} logo={props.logo} />
    <LayoutContent>

      <div className="login-prompt-view">
        <div className="login-prompt-view__content">
          <div className="box">
            <div className="box__title">{props.title}</div>
            <div className="box__body">
              <p>
                {props.description}
              </p>
              {props.error ? <ErrorRenderer error={props.error} /> : ''}
            </div>
            <div className="box__footer">
              <p>{t('Auth.ProblemsWithLogin')}</p>
              <button className="btn btn--default" onClick={props.onButtonClicked}>{t('Auth.LoginNow')}</button>
            </div>
          </div>
        </div>
      </div>

    </LayoutContent>
  </Layout>);
}
