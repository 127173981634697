import { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import { DataModels, Subscription } from '@atlas-engine/atlas_engine_sdk';

import {
  AnyTaskType,
  AtlasEngineService, TaskListWithCorrelation,
} from '../../../lib';

import { ErrorRenderer } from '../ErrorRenderer';

import { filterTaskListsWithCorrelation } from './filterTaskListsWithCorrelation';
import { TasksByCorrelationGroup } from './TasksByCorrelationGroup';
import { DelayedRenderer } from '../DelayedRenderer';

type TaskListProps = {
  atlasEngineService: AtlasEngineService;
  searchFilter?: string;
  showTitle?: boolean;
} & WithTranslation;

type TaskListState = {
  isLoading: boolean;
  loadingError: Error | null;
  groupedTasks: Array<TaskListWithCorrelation>;
  searchFilter: string;
};

class TaskList extends Component<TaskListProps, TaskListState> {

  public state: TaskListState = {
    groupedTasks: [],
    isLoading: true,
    searchFilter: '',
    loadingError: null,
  };

  private taskChangedSubscriptions: Array<Subscription> = [];
  private processChangedSubscriptions: Array<Subscription> = [];

  public async componentDidMount(): Promise<void> {
    this.taskChangedSubscriptions = await this.props.atlasEngineService.onTaskStatesChanged(this.loadData.bind(this));
    this.processChangedSubscriptions = await this.props.atlasEngineService.onProcessInstanceStateChanged(this.loadData.bind(this));
    this.loadData();
  }

  public async componentWillUnmount(): Promise<void> {
    await this.props.atlasEngineService.removeSubscriptions([...this.taskChangedSubscriptions, ...this.processChangedSubscriptions]);
  }

  public render(): JSX.Element {

    if (this.state.loadingError !== null) {
      return <ErrorRenderer error={this.state.loadingError}/>;
    }

    if (this.state.isLoading) {
      return <DelayedRenderer>
        <Alert variant='info'>{this.props.t('TaskList.DataLoading')}</Alert>
      </DelayedRenderer>;
    }

    const showTitle = this.props.showTitle ?? true;
    const searchFilter = this.props.searchFilter ?? '';

    const filteredTaskGroups = filterTaskListsWithCorrelation(
      this.state.groupedTasks,
      searchFilter,
    );

    const hasActiveSearchFilter = this.state.searchFilter?.trim() !== '';

    let correlations: JSX.Element | Array<JSX.Element> | null = null;

    if (hasActiveSearchFilter) {
      if (filteredTaskGroups.length > 0) {
        correlations = filteredTaskGroups.map((e) => (
          <TasksByCorrelationGroup
            key={e.correlation.correlationId}
            searchFilter={searchFilter} {...e}
          />
        ));
      } else {
        correlations = <Alert variant='danger'>{this.props.t('TaskList.NoTasksFound')}</Alert>;
      }
    } else if (filteredTaskGroups.length > 0) {
      correlations = filteredTaskGroups.map((e) => (
        <TasksByCorrelationGroup
          key={e.correlation.correlationId}
          searchFilter={searchFilter} {...e}
        />
      ));
    }

    return (
      <div className="box box--task-list">
        {showTitle && <div className="box__title box__title--task-list">{this.props.t('TaskList.Header')}</div>}
        <div className="box__subtitle box__subtitle--no-padding box__subtitle--task-list">
          <div className="task-list-header">
            <div className="task-list-header__title">
              {this.props.t('TaskList.HeaderProcessModel')}
            </div>
            <div className="task-list-header__task">
              {this.props.t('TaskList.HeaderTasks')}
            </div>
          </div>
        </div>
        <div className="box__body box__body--task-list">
          {correlations ?? <Alert variant='info'>{this.props.t('TaskList.NoTasks')}</Alert>}
        </div>
      </div>
    );
  }

  private async loadData(): Promise<void> {
    try {
      await this.loadTasks();
      this.setState({ loadingError: null });
    } catch (error) {
      this.setState({ loadingError: error });
    }

    this.setState({ isLoading: false });
  }

  private async loadTasks(): Promise<void> {

    const tasks = await this.props.atlasEngineService.getTasks();
    const correlationIds = tasks.map((task) => task.correlationId);
    const uniqueCorrelationIds = [...new Set(correlationIds)];

    const instances = await this.props.atlasEngineService.getProcessInstacesBy({correlationId: uniqueCorrelationIds});

    const groupedTasks = await this.groupTasksByCorrelation(tasks, instances);

    this.setState({ groupedTasks: groupedTasks });
  }

  private async groupTasksByCorrelation(
    tasks: Array<AnyTaskType>,
    instancesWithCorrelationsOfTasks: Array<DataModels.ProcessInstances.ProcessInstance>,
  ): Promise<Array<TaskListWithCorrelation>> {

    if (tasks.length === 0 || instancesWithCorrelationsOfTasks.length === 0) {
      return [];
    }

    const taskListWithCorrelation: {[correlationId: string]: TaskListWithCorrelation} = {};

    for (const instance of instancesWithCorrelationsOfTasks) {
      if(!instance.correlation) {
        continue;
      }

      const isRootProcessInstance = !instance.parentProcessInstanceId;
      const correlationName = isRootProcessInstance ? instance.processModelName : undefined;

      taskListWithCorrelation[instance.correlationId] = {
        correlation: instance.correlation,
        correlationName: correlationName,
        correlationDescription: instance.correlation.metadata?.description,
        taskList: tasks.filter((task) => task.correlationId === instance.correlationId),
      };
    }

    return Object.values(taskListWithCorrelation);
  }

}

export const TranslatedTaskList = withTranslation()(TaskList);
