import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TaskListWithCorrelation } from '../../../lib/index';
import { TextHighlight } from '../TextHighlight';

type TasksByCorrelationGroupProps = {
  searchFilter: string;
} & TaskListWithCorrelation;

export function TasksByCorrelationGroup(props: TasksByCorrelationGroupProps): JSX.Element {
  const { t } = useTranslation();
  const tasks = props.taskList;
  const correlationName = props.correlationName ?? t('TaskList.CorrelationNameUnknown');

  const searchWords = props.searchFilter?.trim() !== '' ? props.searchFilter?.trim().split(/\s+/) : undefined;
  const correlationHasDescription = props.correlationDescription && props.correlationDescription.trim().length > 0;

  return (
    <div className="task">
      <span className="task__title" title={correlationName}>
        <TextHighlight text={correlationName} highlight={searchWords} />
        {
          correlationHasDescription
            && <TextHighlight className="task__correlation-description" text={` (${props.correlationDescription?.trim()})`} highlight={searchWords} />
        }
      </span>
      <span className="task__task">
        {tasks.map((task) => {
          const text = task.flowNodeName ?? t('TaskList.NoTaskName');

          return (
            <Link
              to={`task/${task.correlationId}/${task.processInstanceId}/${task.flowNodeInstanceId}`}
              className="btn btn--task-continue"
              key={task.flowNodeInstanceId}
              title={text}
            >
              <TextHighlight text={text} highlight={searchWords} />
            </Link>
          )
        })}
      </span>
    </div>
  );
}
