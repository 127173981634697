import '../style.scss';

import { Component, useEffect, useState } from 'react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import { IFormStatePersistenceService, PortalConfiguration } from '@atlas-engine-contrib/atlas-ui_contracts';

import {
  AtlasAuthorityUnreachableError,
  AtlasEngineContext,
  AtlasEngineService,
  AtlasEngineUnreachableError,
  AuthService,
  AuthServiceDummy,
  AuthorityUrlNotDefinedError,
  ComponentStatePersistenceService,
  ConfigurationService,
  ExtensionService,
  IAuthService,
  isVersionGreaterEquals,
  parseVersion,
} from '../lib/index';
import {
  Layout, LayoutContent, LayoutHeader, LayoutSidebar,
} from './Layout';
import { ExtensionServiceProvider, IdentityProvider, useIdentity } from './context';
import { GenericViewProps } from './GenericViewProps';
import { HomepageWithRouter } from './views/homepage-view/HomepageView';
import { StartableListViewWithRouter } from './views/startable-list-view/StartableListView';
import { TaskListViewWithRouter } from './views/task-list-view/TaskListView';
import { TaskViewWithRouter } from './views/task-view/TaskViewWithRouter';
import { CorrelationTrackerViewWithRouter } from './views/correlation-tracker-view/CorrelationTrackerViewWithRouter';
import { StartDialogHomepage, StartDialogViewWithRouter } from './views/start-dialog-view/StartDialogView';
import { StartProcessViaUrlView } from './views/start-process-via-url-view/StartProcessViaUrlView';
import { UserSettingsViewWithRouter } from './views/user-settings-view/UserSettingsView';
import { LinkContainer } from 'react-router-bootstrap';
import { PrivateRoute } from './PrivateRoute';
import { registerWebComponents } from '@atlas-engine-contrib/atlas-ui_user-interaction-component';
import { FlashMessage } from './components/FlashMessage';
import { ErrorRenderer } from './components/ErrorRenderer';

type AppBootstrapperProps = WithTranslation;

type AuthSigninCallbackProps = GenericViewProps & {
  authService: IAuthService;
}

type AuthSignoutCallbackProps = {
  authService: IAuthService;
}

type RouteEntry = {
  path: string;
  activeNav?: string;
  exact?: boolean;
  loginRequired: boolean;
  header?: {
    title: string;
    showSearch: boolean;
  };
  main: (props: GenericViewProps) => JSX.Element;
}

export type AppBootstrapperState = {
  appConfig: PortalConfiguration | null;
  error: Error | null;
  sidebarVisible: boolean;
  willReload: boolean;
  atlasEngineVersion?: string;
};

registerWebComponents();

class AppBootstrapper extends Component<AppBootstrapperProps, AppBootstrapperState> {

  private configurationService: ConfigurationService;
  private extensionService!: ExtensionService;
  private authService!: IAuthService;
  private routes: Array<RouteEntry>;
  private componentStatePersistenceService!: IFormStatePersistenceService
  private atlasEngineService!: AtlasEngineService;

  private readonly minRequiredAtlasEngineVersion = '11.0.0';

  constructor(props: AppBootstrapperProps) {
    super(props);

    this.configurationService = new ConfigurationService();

    this.state = {
      appConfig: null,
      error: null,
      sidebarVisible: false,
      willReload: false,
    };

    this.routes = [
      {
        path: '/',
        exact: true,
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => {

          if (this.state.appConfig?.useStartDialogAsHomepage != null && this.state.appConfig?.useStartDialogAsHomepage.trim() !== '') {
            return (
              <StartDialogHomepage
                {...genericViewProps}
                startDialogId={this.state.appConfig?.useStartDialogAsHomepage}
                config={this.state.appConfig?.startDialogs as any}
                authService={this.authService}
              />
            )
          }

          return (
            <HomepageWithRouter
              {...genericViewProps}
              atlasEngineService={this.atlasEngineService}
              authService={this.authService}
              startDialogsConfig={this.state.appConfig?.startDialogs as any}
              startablesOrder={this.state.appConfig?.startablesOrder}
              startableGroups={this.state.appConfig?.startableGroups}
              sidebarVisible={this.state.sidebarVisible}
              hideSidebar={(): void => this.hideSidebar()}
            />
          )},
      },
      {
        path: '/startable-list',
        exact: true,
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <StartableListViewWithRouter
            {...genericViewProps}
            atlasEngineService={this.atlasEngineService}
            authService={this.authService}
            startDialogsConfig={this.state.appConfig?.startDialogs as any}
            startablesOrder={this.state.appConfig?.startablesOrder}
            startableGroups={this.state.appConfig?.startableGroups}
          />
        ),
      },
      {
        path: '/task-list',
        exact: true,
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <TaskListViewWithRouter
            {...genericViewProps}
            atlasEngineService={this.atlasEngineService}
            startDialogsConfig={this.state.appConfig?.startDialogs as any}
          />
        ),
      },
      {
        path: '/task/:correlationId/:processInstanceId/:flowNodeInstanceId',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <TaskViewWithRouter
            {...genericViewProps}
            componentStatePersistenceService={this.componentStatePersistenceService}
            authService={this.authService}
            taskViewConfig={this.state.appConfig?.taskViewConfig as any}
          />
        ),
      },
      {
        path: '/correlation/:correlationId/',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <CorrelationTrackerViewWithRouter
            {...genericViewProps}
          />
        ),
      },
      {
        path: '/startdialog/:startDialogId/:payload?',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <StartDialogViewWithRouter
            {...genericViewProps}
            config={this.state.appConfig?.startDialogs as any}
            authService={this.authService}
          />
        ),
      },
      {
        path: '/start/:processModelId/:payload?',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <StartProcessViaUrlView {...genericViewProps} atlasEngineService={this.atlasEngineService} />
        ),
      },
      {
        path: '/user-settings',
        exact: true,
        loginRequired: false,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <UserSettingsViewWithRouter
            {...genericViewProps}
            authService={this.authService}
            languages={this.state.appConfig?.languages}
          />
        ),
      },
      {
        path: '/signin-oidc',
        loginRequired: false,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <AuthSigninCallback
            {...genericViewProps}
            authService={this.authService}
          />
        ),
      },
      {
        path: '/signout-oidc',
        loginRequired: false,
        main: (): JSX.Element => (
          <AuthSignoutCallback
            authService={this.authService}
          />
        ),
      },

    ];
  }

  public async componentDidMount(): Promise<void> {
    try {
      const appConfig = await this.configurationService.loadConfig();
      this.setState({ appConfig: appConfig });

      if (appConfig.favicon.trim() !== '') {
        this.addFavicon(appConfig.favicon);
      }

      this.extensionService = new ExtensionService(appConfig);
      await this.extensionService.loadExtensions();

      this.authService = appConfig.useAuthority
        ? await AuthService.create(appConfig.authorityConfiguration)
        : new AuthServiceDummy();

      this.componentStatePersistenceService = new ComponentStatePersistenceService(this.authService);
      this.atlasEngineService = new AtlasEngineService(this.authService, appConfig);
      await this.authService.detectLoggedInFlag();

      const atlasEngineVersion = await this.atlasEngineService.getAtlasEngineVersion();
      this.setState({ atlasEngineVersion: atlasEngineVersion });
    } catch (error) {
      this.setState({
        error: error,
      });
    }
  }

  public componentDidUpdate(): void {
    const { error, willReload } = this.state;

    if (error && !willReload) {
      if (error instanceof AtlasAuthorityUnreachableError || error instanceof AtlasEngineUnreachableError || error instanceof AuthorityUrlNotDefinedError) {
        this.reloadPageDeferred();
      }
    }

    if (this.props.tReady) {
      document.title = this.props.t('ApplicationTitle');
    }
  }

  public render(): JSX.Element | null {
    const { t } = this.props;
    const logo = this.state.appConfig ? this.state.appConfig.logo : '';

    const genericViewProps: GenericViewProps = {
      sidebarVisible: this.state.sidebarVisible,
      hideSidebar: () => this.hideSidebar(),
      onMenuClick: () => this.toggleSidebar(),
      logo: logo,
    };

    const atlasEngineVersionIncompatibleMessage = this.isAtlasEngineVersionCompatible() === false
      ? <FlashMessage className="app-sticky-message" variant="warning" text={t('AtlasEngineOutdated', { version: this.minRequiredAtlasEngineVersion })} />
      : null;

    return (
      <ExtensionServiceProvider extensionService={this.extensionService}>
        <IdentityProvider authService={this.authService}>
          <AtlasEngineContext.Provider value={this.atlasEngineService}>
            {atlasEngineVersionIncompatibleMessage}
            <BrowserRouter>
              <Switch>
                {this.routes
                  .map((route, index) => {
                    if (route.loginRequired) {
                      return (
                        <PrivateRoute
                          bootstrapError={this.state.error != null}
                          authService={this.authService}
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          {...genericViewProps}
                        >
                          {this.state.error ?
                            <BootstrapError {...genericViewProps} error={this.state.error} willReload={this.state.willReload} />
                            : <route.main {...genericViewProps} />
                          }

                        </PrivateRoute>
                      );
                    }

                    const isUserSettingsPage = route.path === '/user-settings';
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                      >
                        {isUserSettingsPage || !this.state.error ?
                          <route.main {...genericViewProps} /> :
                          <BootstrapError {...genericViewProps} error={this.state.error} willReload={this.state.willReload} />}
                      </Route>
                    );
                  })}
              </Switch>
            </BrowserRouter>
          </AtlasEngineContext.Provider>
        </IdentityProvider>
      </ExtensionServiceProvider>
    );
  }

  private toggleSidebar(): void {
    this.setState({ sidebarVisible: !this.state.sidebarVisible });
  }

  private hideSidebar(): void {
    this.setState({ sidebarVisible: false });
  }

  private reloadPageDeferred(): void {
    this.setState({ willReload: true });

    setTimeout(() => {
      window.location.reload();
    }, 30 * 1000);
  }

  private addFavicon(faviconUrl: string): void {
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = faviconUrl;

    document.head.appendChild(favicon);
  }

  private isAtlasEngineVersionCompatible(): boolean | null {
    if (!this.state.atlasEngineVersion) {
      return null;
    }

    const parsedAtlasEngineVersion = parseVersion(this.state.atlasEngineVersion);
    if (!parsedAtlasEngineVersion) {
      console.warn('Could not parse 5Minds Engine version.');
      return null;
    }

    const parsedMinRequiredAtlasEngineVersion = parseVersion(this.minRequiredAtlasEngineVersion);
    if (!parsedMinRequiredAtlasEngineVersion) {
      console.warn('Could not parse minimum required 5Minds Engine version.');
      return null;
    }

    return isVersionGreaterEquals(parsedAtlasEngineVersion, parsedMinRequiredAtlasEngineVersion);
  }

}

export const TranslatedAppBootstrapper = withTranslation()(AppBootstrapper);

/**
 * This component is rendered when the user signed in and is being redirected by the OpenID provider.
 */
function AuthSigninCallback(props: AuthSigninCallbackProps): JSX.Element | null {
  const { authService } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const { setIdentity } = useIdentity();
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    authService?.processSigninResponse()
      .then((result) => {
        setIdentity(result.identity);
        history.replace(result.targetRoute as any);
      })
      .catch((err) => {
        setError(err);
      });
  }, [authService]);

  const handleTryAgain = (event: any): void => {
    event.preventDefault();
    authService.login();
  }

  const title = error != null ? t('ErrorTitle') : '';

  return (
    <Layout>
      <LayoutSidebar hideSidebar={props.hideSidebar} visible={props.sidebarVisible} logo={props.logo} />
      <LayoutHeader title={title} onMenuClick={props.onMenuClick} showSearch={false} />
      <LayoutContent>
        {error != null &&
            <Alert variant='danger'>
              <Alert.Heading>{t('Auth.ErrorDuringLogin')}</Alert.Heading>
              <pre className="alert-danger">{error.message}</pre>
              <p>
                <LinkContainer onClick={handleTryAgain} to="">
                  <Alert.Link>{t('TryAgain')}</Alert.Link>
                </LinkContainer>
              </p>
            </Alert>
        }

        {error == null &&
          <Alert variant='info'>{t('Auth.LoadingUserInformation')}</Alert>
        }
      </LayoutContent>
    </Layout>
  );
}

/**
 * This component is rendered when the user signed out and is being redirected by the OpenID provider.
 */
function AuthSignoutCallback(props: AuthSignoutCallbackProps): null {
  const { authService } = props;
  const history = useHistory();
  const { setIdentity } = useIdentity();

  useEffect(() => {
    if (!authService) {
      history.replace('/');
      return;
    }
    authService?.processSignoutResponse()
      .then(() => {
        setIdentity(null);
        history.replace('/');
      });
  }, [authService]);

  return null;
}

function BootstrapError(props: GenericViewProps & {error: Error; willReload: boolean}): JSX.Element  {
  const {t} = useTranslation();

  return (

    <Layout>
      <LayoutSidebar hideSidebar={props.hideSidebar} visible={props.sidebarVisible} logo={props.logo} />
      <LayoutHeader title={t('ErrorTitle')} onMenuClick={props.onMenuClick} showSearch={false} />
      <LayoutContent>
        <ErrorRenderer error={props.error} />
        {props.willReload &&
          <Alert variant="info">
            {t('PageWillRefreshAutomatically')}
          </Alert>
        }
      </LayoutContent>
    </Layout>
  )
}
