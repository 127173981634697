import { ComponentType } from 'react';

import { PortalConfiguration } from '@atlas-engine-contrib/atlas-ui_contracts';

export class Portal {

  public config: PortalConfiguration;

  private renderHooks: {[id: string]: Array<ComponentType>} = {};

  constructor(config: PortalConfiguration) {
    this.config = config;
  }

  public registerRenderHook(id: string, reactComponent: ComponentType): void {
    if (this.renderHooks[id] == null) {

      this.renderHooks[id] = [];
    }

    this.renderHooks[id].push(reactComponent);
  }

  public getRenderHooks(id: string): Array<ComponentType> | null {
    return this.renderHooks[id] || null;
  }

}
