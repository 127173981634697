import { useContext } from 'react';
import * as router from 'react-router-dom';

import { IFormStatePersistenceService, TaskViewConfig } from '@atlas-engine-contrib/atlas-ui_contracts';

import {
  AtlasEngineContext,
  IAuthService,
} from '../../../lib/index';
import { TaskView } from './TaskView';
import { GenericViewProps } from '../../GenericViewProps';

type RoutedTaskViewProps = {
  taskViewConfig: TaskViewConfig;
  componentStatePersistenceService: IFormStatePersistenceService;
  authService: IAuthService;
} & GenericViewProps;

type TaskViewParameters = {
  correlationId: string;
  processInstanceId: string;
  flowNodeInstanceId: string;
}

export function TaskViewWithRouter(props: RoutedTaskViewProps): JSX.Element {
  const {
    correlationId,
    processInstanceId,
    flowNodeInstanceId,
  } = router.useParams<TaskViewParameters>();

  const atlasEngineService = useContext(AtlasEngineContext);

  if (!atlasEngineService) {
    throw new Error('Atlas Engine Service missing in app context');
  }

  if (!correlationId || !processInstanceId || !flowNodeInstanceId) {
    return <span>Error</span>;
  }

  return (
    <TaskView
      {...props}
      correlationId={correlationId}
      processInstanceId={processInstanceId}
      flowNodeInstanceId={flowNodeInstanceId}
      atlasEngineService={atlasEngineService}
      taskViewConfig={props.taskViewConfig}
      componentStatePersistenceService={props.componentStatePersistenceService}
    ></TaskView>
  );
}
