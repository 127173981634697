import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';

import { TranslatedAppBootstrapper } from './modules/AppBootstrapper';
import { AppSkeleton } from './modules/AppSkeleton';

const extensionsRequire = (name: string): any => {
  if( name === 'react') {
    return React;
  }
  console.warn('Call to require:', name)
}

(window as any).require = extensionsRequire;

ReactDOM.render(
  <Suspense fallback={<AppSkeleton />}><TranslatedAppBootstrapper/></Suspense>,
  document.getElementById('root'),
);
