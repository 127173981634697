import { Component, PropsWithChildren } from 'react';

type DelayedRendererProps = PropsWithChildren<{
  timeoutInMs?: number;
}>

type DelayedRendererState = {
  ready: boolean;
}

/**
 * Displays its children after a set amount of time.
 *
 *      <DelayedRenderer>Loading ...</DelayedRenderer>
 *
 * This is used to display elements and messages *only* if nothing happened after the given amount of time,
 * thus avoiding the typical flickering of these elements.
 */
export class DelayedRenderer extends Component<DelayedRendererProps, DelayedRendererState> {

  private timeoutId: any;

  constructor(props: DelayedRendererProps) {
    super(props);
    this.state = { ready: false };
  }

  public componentDidMount(): void {
    this.timeoutId = setTimeout(() => this.setState({ ready: true }), this.props.timeoutInMs || 800);
  }

  public componentWillUnmount(): void {
    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId);
    }
  }

  public render(): React.ReactNode | null {
    if (this.state.ready) {
      return this.props.children;
    }

    return null;
  }

}
