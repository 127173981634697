import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { PortalConfiguration } from '@atlas-engine-contrib/atlas-ui_contracts';

import { IAuthService } from '../../../lib';
import i18n from '../../../i18n';
import {Layout, LayoutContent, LayoutHeader, LayoutSidebar} from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';
import ProductInfo from '../../../generatedProductInfo';

export type UserSettingsViewProps = {
  authService?: IAuthService;
  languages?: PortalConfiguration['languages'];
} & RouteComponentProps & WithTranslation & GenericViewProps;

function UserSettingsView(props: UserSettingsViewProps): JSX.Element {
  const { t } = props;

  const i18nLanguage = i18n.language.replace(/-.+$/, '');
  const changeLanguage = (lng: string): void => {
    i18n.changeLanguage(lng);
  };

  let currentLanguage = i18n.options.fallbackLng?.toString() as string;

  if (props.languages) {
    const languages = Object.keys(props.languages);
    const languageIsUsable = languages.some((lang) => lang === i18nLanguage)
    if (languageIsUsable) {
      currentLanguage = i18nLanguage;
    } else {
      if (!languages.some((lang) => lang === currentLanguage) && languages.length > 0) {
        currentLanguage = languages[0];
      }
      changeLanguage(currentLanguage);
    }
  }

  const isLoggedIn = props.authService?.isLoggedIn();

  return (
    <Layout>
      <LayoutSidebar activeNav="user-settings" visible={props.sidebarVisible} hideSidebar={props.hideSidebar} logo={props.logo} />
      <LayoutHeader title={t('Header.TitleUserSettings')} showSearch={false} onMenuClick={props.onMenuClick} />
      <LayoutContent>

        <div className="user-settings-view">
          <div className="user-settings-view__content">

            <div className="box">
              <div className="box__title">
                {props.t('UserSettings.HeaderLanguages')}
              </div>
              <div className="box__body">
                <div className='form-group'>
                  <select className="form-control custom-select custom-select-sm user-settings-view__language" value={currentLanguage} onChange={(event): void => changeLanguage(event.target.value)}>
                    {props.languages &&  Object.entries(props.languages).map((languageEntry, index) => {
                      const value = languageEntry[0];
                      const displayName = languageEntry[1];

                      return <option key={`${value}_${displayName}_${index}`} value={value}>{displayName}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>

            {isLoggedIn && <div className="box">
              <div className="box__title">
                {props.t('UserSettings.HeaderLogout')}
              </div>
              <div className="box__body">
                <button className="btn btn-outline-danger btn--logout" onClick={(): void => props.authService?.logout()}>{props.t('UserSettings.Logout')}</button>
              </div>
            </div>}

            <div className="user-settings-view__version">
              Portal Version {ProductInfo.version.backend} (packaged with Backend Version {ProductInfo.version.frontend})
            </div>

          </div>
        </div>

      </LayoutContent>
    </Layout>
  );

}

export const UserSettingsViewWithRouter = withTranslation()(withRouter(UserSettingsView));
