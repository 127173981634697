import { Component, createRef } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  IFormStatePersistenceService,
  ManualTaskAbortedEvent,
  ManualTaskFinishedEvent,
  ManualTaskSuspendedEvent,
  ProcessInstanceTerminateEvent,
  ProcessInstanceTerminatedEvent,
  TaskViewConfig,
  UserTaskAbortedEvent,
  UserTaskFinishedEvent,
  UserTaskSuspendedEvent,
} from '@atlas-engine-contrib/atlas-ui_contracts';
import { UserInteractionComponent } from '@atlas-engine-contrib/atlas-ui_user-interaction-component';
import { BpmnType } from '@atlas-engine/atlas_engine_sdk';

import {
  AnyTaskType,
  AtlasEngineService,
  IdentityWithEmail,
} from '../../../lib/index';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import { BackToHomepage } from '../../components/BackToHomepage';
import { Layout, LayoutContent, LayoutSidebar } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';
import { WithIdentity, withIdentity } from '../../context';

type TaskViewState = {
  task?: AnyTaskType | null;
  taskIsFinished: boolean;
  isLoading: boolean;
  error?: Error;
  showConfirmTerminationModal: boolean;
};

type TaskViewProps = RouteComponentProps & {
  atlasEngineService: AtlasEngineService;
  taskViewConfig: TaskViewConfig;
  correlationId: string;
  processInstanceId: string;
  flowNodeInstanceId: string;
  componentStatePersistenceService: IFormStatePersistenceService;
} & WithTranslation & WithIdentity & GenericViewProps;

class TaskViewComponent extends Component<TaskViewProps, TaskViewState> {
  public state: TaskViewState = {
    task: null,
    taskIsFinished: false,
    isLoading: true,
    showConfirmTerminationModal: false,
  }

  private navigateToCorrelationWithoutAutoRedirectBound = this.navigateToCorrelationWithoutAutoRedirect.bind(this);
  private handleTaskFinishedBound = this.handleTaskFinished.bind(this);
  private handleOnProcessInstanceTerminateBound = this.handleOnProcessInstanceTerminate.bind(this);
  private handleOnProcessInstanceTerminatedBound = this.handleOnProcessInstanceTerminated.bind(this);

  private userInteractionComponent = createRef<UserInteractionComponent>();
  private resolveConfirmTerminationModal: (result: 'confirm' | 'cancel') => void = () => void 0;

  public componentDidMount(): void {
    this.loadData();
  }

  public componentDidUpdate(prevProps: TaskViewProps, prevState: TaskViewState): void {
    if (!this.state.isLoading && !this.state.taskIsFinished && this.state.isLoading !== prevState.isLoading) {
      this.handleUserInteractionComponentMount();
    }

    if (this.props.identity?.token !== prevProps.identity?.token) {
      this.handleIdentityChanged(this.props.identity);
    }
  }

  public async componentWillUnmount(): Promise<void> {
    if (
      this.state.task &&
      this.state.task.flowNodeType === BpmnType.userTask &&
      !this.state.taskIsFinished
    ) {
      await this.props.atlasEngineService.cancelUserTaskReservation(this.state.task.flowNodeInstanceId);
    }

    this.handleUserInteractionComponentUnmount();
  }

  public render(): JSX.Element {
    return <Layout>
      <LayoutSidebar visible={this.props.sidebarVisible} hideSidebar={this.props.hideSidebar} logo={this.props.logo} />
      <LayoutContent>

        <div className={`task-view task-view--${this.state.task?.flowNodeId?.trim().replaceAll(' ', '-')}`}>
          <BackToHomepage />
          <div className="task-view__content">
            {this.state.error && <ErrorRenderer error={this.state.error} />}
            {this.renderContent()}
            {this.renderConfirmTerminationModal()}
          </div>
        </div>
      </LayoutContent>
    </Layout>;
  }

  private renderContent(): JSX.Element | null {
    if (this.state.isLoading) {
      return <DelayedRenderer>{this.props.t('TaskView.Loading')}</DelayedRenderer>;
    }

    const taskWasNotFound = !this.props.identity || !this.state.task;
    if (taskWasNotFound) {
      return <Alert variant='warning'>
        {this.props.t('TaskView.TaskNotFound')}
        <LinkContainer className='ml-1' to={`/correlation/${this.props.correlationId}`}>
          <Alert.Link>
            {this.props.t('TaskView.SwitchToOverview')}
          </Alert.Link>
        </LinkContainer>
      </Alert>;
    }

    return <user-interaction-component ref={this.userInteractionComponent} style={{ height: '100%' }}/>;
  }

  private renderConfirmTerminationModal(): JSX.Element {
    return (
      <Modal
        show={this.state.showConfirmTerminationModal}
        onHide={(): void => this.resolveConfirmTerminationModal('cancel')}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.t('TerminationModal.Title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans
            i18nKey="TerminationModal.Body"
            components={{
              br: <br />,
              b: <b />,
            }}
          ></Trans>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(): void => this.resolveConfirmTerminationModal('cancel')}>{this.props.t('TerminationModal.CancelButton')}</Button>
          <Button variant="danger" onClick={(): void => this.resolveConfirmTerminationModal('confirm')}>{this.props.t('TerminationModal.ConfirmButton')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  private async loadData(): Promise<void> {
    try {
      await Promise.all([
        this.loadTask(),
      ]);
    } catch (error) {
      this.setState({ error: error });
    }

    this.setState({ isLoading: false });
  }

  private async loadTask(): Promise<void> {
    const task = await this.props.atlasEngineService.getTaskByFlowNodeInstanceId(this.props.flowNodeInstanceId);

    if (task?.flowNodeType === BpmnType.userTask) {
      await this.props.atlasEngineService.reserveUserTask(task.flowNodeInstanceId);
    }

    this.setState({ task: task ?? null });
  }

  private handleUserInteractionComponentMount(): void {
    const { task } = this.state;
    const { identity } = this.props;
    const userInteractionComponent = this.userInteractionComponent.current;
    // check if task and identity are loaded, the task has a flowNodeInstance and if the
    // userInteractionComponent is mounted.
    // We do it this way here instead of moving these checks to variables, so that typescript
    // knows, that these variables are not undefined after the early-exit.
    if (!task || !identity || !task?.flowNodeInstanceId || !userInteractionComponent) {
      return;
    }

    this.handleUserInteractionComponentUnmount();
    userInteractionComponent.identity = identity;
    userInteractionComponent.customFormConfig = {};
    userInteractionComponent.atlasEngineBaseUrl = this.props.atlasEngineService.getAtlasEngineBaseUrl();
    userInteractionComponent.disableAutoWait = true;
    userInteractionComponent.customFormResolver = this.props.taskViewConfig.resolveCustomForm;
    userInteractionComponent.formStatePersistentService = this.props.componentStatePersistenceService;
    userInteractionComponent.showTerminateButtonIfUserHasClaim = true;

    if (task.flowNodeType === BpmnType.userTask) {
      userInteractionComponent.displayUserTask(task.flowNodeInstanceId);
    } else if (task.flowNodeType === BpmnType.manualTask) {
      userInteractionComponent.displayManualTask(task.flowNodeInstanceId);
    } else {
      throw new Error('Unknown task type.');
    }

    userInteractionComponent.addEventListener(ManualTaskSuspendedEvent.type, this.navigateToCorrelationWithoutAutoRedirectBound);
    userInteractionComponent.addEventListener(ManualTaskFinishedEvent.type, this.handleTaskFinishedBound);
    userInteractionComponent.addEventListener(ManualTaskAbortedEvent.type, this.navigateToCorrelationWithoutAutoRedirectBound);
    userInteractionComponent.addEventListener(UserTaskSuspendedEvent.type, this.navigateToCorrelationWithoutAutoRedirectBound);
    userInteractionComponent.addEventListener(UserTaskFinishedEvent.type, this.handleTaskFinishedBound);
    userInteractionComponent.addEventListener(UserTaskAbortedEvent.type, this.navigateToCorrelationWithoutAutoRedirectBound);
    userInteractionComponent.addEventListener(ProcessInstanceTerminateEvent.type, this.handleOnProcessInstanceTerminateBound);
    userInteractionComponent.addEventListener(ProcessInstanceTerminatedEvent.type, this.handleOnProcessInstanceTerminatedBound);
  }

  private handleUserInteractionComponentUnmount(): void {
    const userInteractionComponent = this.userInteractionComponent.current;
    if (!userInteractionComponent) {
      return;
    }

    userInteractionComponent.removeEventListener(ManualTaskSuspendedEvent.type, this.navigateToCorrelationWithoutAutoRedirectBound);
    userInteractionComponent.removeEventListener(ManualTaskFinishedEvent.type, this.handleTaskFinishedBound);
    userInteractionComponent.removeEventListener(ManualTaskAbortedEvent.type, this.navigateToCorrelationWithoutAutoRedirectBound);
    userInteractionComponent.removeEventListener(UserTaskSuspendedEvent.type, this.navigateToCorrelationWithoutAutoRedirectBound);
    userInteractionComponent.removeEventListener(UserTaskFinishedEvent.type, this.handleTaskFinishedBound);
    userInteractionComponent.removeEventListener(UserTaskAbortedEvent.type, this.navigateToCorrelationWithoutAutoRedirectBound);
    userInteractionComponent.removeEventListener(ProcessInstanceTerminateEvent.type, this.handleOnProcessInstanceTerminateBound);
    userInteractionComponent.removeEventListener(ProcessInstanceTerminatedEvent.type, this.handleOnProcessInstanceTerminatedBound)
  }

  private handleIdentityChanged(newIdentity: IdentityWithEmail | null): void {
    const userInteractionComponent = this.userInteractionComponent.current;

    if (userInteractionComponent != null) {
      userInteractionComponent.identity = newIdentity;
    }
  }

  private async handleOnProcessInstanceTerminate(event: ProcessInstanceTerminateEvent): Promise<void> {
    event.preventDefault();

    this.setState({showConfirmTerminationModal: true})

    const result = await new Promise((resolve: (result: 'confirm' | 'cancel') => void) => {
      this.resolveConfirmTerminationModal = resolve;
    });

    this.setState({showConfirmTerminationModal: false});

    if (result === 'cancel') {
      event.abort = true;
      return;
    }

    if (this.state.task != null) {
      try {
        await this.props.atlasEngineService.terminateProcessInstance(this.state.task.processInstanceId);
      } catch (error) {
        event.error = error;
        this.setState({error: error});
      }
    }
  }

  private async handleOnProcessInstanceTerminated(): Promise<void> {
    this.props.history.push('/');
  }

  private handleTaskFinished(): void {
    this.setState({ taskIsFinished: true });
    this.navigateToCorrelation();
  }

  private navigateToCorrelation(): void {
    this.props.history.push(`/correlation/${this.props.correlationId}`);
  }

  private navigateToCorrelationWithoutAutoRedirect(): void {
    this.props.history.push(`/correlation/${this.props.correlationId}?autoNavigate=false`);
  }

}

export const TaskView = withIdentity(withTranslation()(withRouter(TaskViewComponent)));
