import { useEffect, useState } from 'react';
import { AppHeader } from './components/AppHeader';
import { AppSidebar } from './components/AppSidebar';

type LayoutProps = any;

type LayoutSidebarProps = {
  activeNav?: string;
  visible: boolean;
  hideSidebar: () => void;
  logo: string;
};

type LayoutHeaderProps = LayoutHeaderPropsWithSearch | LayoutHeaderPropsWithoutSearch;

type LayoutHeaderPropsWithSearch = {
  title?: string;
  onMenuClick: () => void;
  showSearch: true;
  onSearchChanged: (value: string) => void;
};

type LayoutHeaderPropsWithoutSearch = {
  title?: string;
  onMenuClick: () => void;
  showSearch: false;
};

type LayoutContentProps = any;

export function Layout(props: LayoutProps): JSX.Element {
  const [classNameString, setClassNameString] = useState('');

  useEffect(() => {
    const layoutContent = props.children?.find((child: any) => child?.type?.name === LayoutContent.name);
    const contentChildren = Array.isArray(layoutContent?.props?.children) ? layoutContent?.props?.children : [layoutContent?.props?.children];
    const classNames = contentChildren.map((child: any) => {
      const classes = child?.props?.className ?? '';
      return classes.split(' ');
    }) as Array<string>;

    const flattenClassNames = classNames.flat().filter((entry: any) => entry != null && entry !== '')
    const mappedClassNames = flattenClassNames.map((className: any) => `app-layout--${className}`);

    setClassNameString(mappedClassNames.join(' '));
  }, [props.children]);

  return <div className={`app-layout ${classNameString}`}>
    {props.children}
  </div>;
}

export function LayoutSidebar(props: LayoutSidebarProps): JSX.Element {
  return <div className="app-layout__app-sidebar">
    <AppSidebar
      activeNav={props.activeNav}
      visible={props.visible}
      hideSidebar={props.hideSidebar}
      logo={props.logo}
    />
  </div>;

}

export function LayoutHeader(props: LayoutHeaderProps): JSX.Element {
  return <div className="app-layout__app-header">
    <AppHeader
      onMenuClick={props.onMenuClick}
      onSearchChanged={props.showSearch ? props.onSearchChanged : undefined}
      showSearch={props.showSearch}
      title={props.title}
    />
  </div>;
}

export function LayoutContent(props: LayoutContentProps): JSX.Element {
  return <div className="app-layout__content">
    {props.children}
  </div>;
}
