import {PropsWithChildren} from 'react';
import { Route } from 'react-router-dom';

import { IAuthService } from '../lib/index';
import { GenericViewProps } from './GenericViewProps';
import { LoginPromptView } from './views/login-prompt-view/LoginPromptView';

export type PrivateRouteProps = GenericViewProps & {
  bootstrapError: boolean;
  authService?: IAuthService;
  path: string;
  exact: boolean | undefined;
};

export function PrivateRoute(props: PropsWithChildren<PrivateRouteProps>): JSX.Element | null {

  const {
    children,
    authService,
    ...rest
  } = props;

  return (

    <Route
      {...rest}
      render={({ location }): any => {
        if (authService?.isLoggedIn() || props.bootstrapError) {
          return children;
        }

        if (!authService) {
          return null;
        }

        return <LoginPromptView {...props} authService={authService} redirectAfterLogin={location} />;
      }}
    />
  );
}
