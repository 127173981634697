export type ParsedVersion = {
  baseString: string;
  releaseChannelName: string;
  releaseChannelNumber?: number;
  versionIdentifiers: VersionIdentifiers;
};

type VersionIdentifiers = {
  major: number;
  minor: number;
  patch: number;
}

const RELEASE_CHANNEL_NAME_STABLE = 'stable';

export function parseVersion(version: string): ParsedVersion | null {
  const isSemVer = version.match(/^\d+\.\d+\.\d+/) != null;
  if (!isSemVer) {
    return null;
  }

  const isPreVersion = version.includes('-');

  if (isPreVersion) {
    const parts = version.split('-');
    const baseString = parts[0];
    const releaseChannelString = parts[1];

    const isNumberedReleaseChannel = releaseChannelString.includes('.');
    if (!isNumberedReleaseChannel) {
      return null;
    }

    const releaseChannelParts = releaseChannelString.split('.');
    const releaseChannelName = releaseChannelParts[0];
    const releaseChannelNumber = parseInt(releaseChannelParts[1]);
    const versionIdentifiers = getVersionIdentifiers(baseString);

    return {
      baseString: baseString,
      releaseChannelName: releaseChannelName,
      releaseChannelNumber: releaseChannelNumber,
      versionIdentifiers: versionIdentifiers,
    };
  }

  return {
    baseString: version,
    releaseChannelName: RELEASE_CHANNEL_NAME_STABLE,
    versionIdentifiers: getVersionIdentifiers(version),
  };
}

export function compareVersions(a: ParsedVersion, b: ParsedVersion): number {
  return (
    a.versionIdentifiers.major - b.versionIdentifiers.major ||
    a.versionIdentifiers.minor - b.versionIdentifiers.minor ||
    a.versionIdentifiers.patch - b.versionIdentifiers.patch
  );
}

export function isVersionGreaterEquals(a: ParsedVersion, b: ParsedVersion): boolean {
  return compareVersions(a, b) >= 0;
}

function getVersionIdentifiers(version: string): VersionIdentifiers {
  const versionParts = version.split('.');

  return {
    major: parseInt(versionParts[0]),
    minor: parseInt(versionParts[1]),
    patch: parseInt(versionParts[2]),
  };
}
