import { Component } from 'react';
import { Alert, AlertProps } from 'react-bootstrap';

export type FlashMessageProps = {
  variant: AlertProps['variant'];
  text: string;
  className?: string;
}

export type FlashMessageState = {
  isActive: boolean;
}

export class FlashMessage extends Component<FlashMessageProps, FlashMessageState> {

  public state = {
    isActive: true,
  }

  public render(): JSX.Element | null {
    if (!this.state.isActive) {
      return null;
    }

    const className = this.props.className
      ? `${this.props.className} flash-message`
      : 'flash-message';

    return (
      <Alert className={className} variant={this.props.variant}>
        {this.props.text}
        <button className="flash-message__cancel-button" onClick={this.closeFlashMessage}>×</button>
      </Alert>
    );
  }

  private closeFlashMessage = (): void => {
    this.setState({ isActive: false });
  }

}
