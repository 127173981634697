import {
  PropsWithChildren,
  createContext,
  useContext,
} from 'react';

import { ExtensionService } from '../../lib';

type ExtensionServiceProviderProps = {
  extensionService: ExtensionService | null;
};

const ExtensionServiceContext = createContext<ExtensionService | null>(null);

export function ExtensionServiceProvider(props: PropsWithChildren<ExtensionServiceProviderProps>): JSX.Element {
  return (
    <ExtensionServiceContext.Provider value={props.extensionService}>
      {props.children}
    </ExtensionServiceContext.Provider>
  );
}

export function useExtensionService(): ExtensionService | null {
  return useContext(ExtensionServiceContext);
}

export type WithExtensionService = ExtensionService | null;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function withExtensionService<TProps extends WithExtensionService>(Component: React.ComponentType<TProps>) {
  const ComponentWithExtensionService = (props: Omit<TProps, keyof WithExtensionService>) => {
    const extensionService = useExtensionService();

    // Unfortunately, the type assertion is necessary due to a likely bug in TypeScript
    // https://github.com/Microsoft/TypeScript/issues/28938
    return <Component {...props as TProps} extensionService={extensionService} />;
  };

  const componentName = Component.displayName ?? Component.name ?? 'Component';
  ComponentWithExtensionService.displayName = `WithExtensionService(${componentName})`;

  return ComponentWithExtensionService;
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
