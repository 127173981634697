import { Component, createRef, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { Identity } from '@atlas-engine/atlas_engine_sdk';
import {
  DisplayStartDialogMessage,
  StartDialogConfig,
  StartDialogMessageType,
  StartDialogsConfig,
  UpdateIdentityStartDialogMessage,
  isCloseStartDialogMessage,
  isOpenStartDialogMessage,
  isStartProcessStartDialogMessage,
} from '@atlas-engine-contrib/atlas-ui_contracts';
import { Base64 } from 'js-base64';

import { IAuthService, getAllowedStartDialogs } from '../../../lib';
import { GenericViewProps } from '../../GenericViewProps';
import { Layout, LayoutContent, LayoutSidebar } from '../../Layout';
import { BackToHomepage } from '../../components/BackToHomepage';
import { useIdentity } from '../../context';
import { ErrorRenderer } from '../../components/ErrorRenderer';

type StartDialogViewProps = {
  startDialogId: string;
  config: StartDialogConfig;
  navigate: (url: string) => void;
  identity: Identity;
  payload?: Record<string, unknown>;
  iFrameStyle?: React.CSSProperties;
  className?: string;
};

type StartDialogViewWithRouterProps = {
  authService: IAuthService;
  config: StartDialogsConfig;
} & GenericViewProps;

type StartDialogHomepageProps = StartDialogViewWithRouterProps & {
  startDialogId: string;
}

type StartDialogViewParameters = {
  startDialogId: string;
  payload?: string;
}

class StartDialogView extends Component<StartDialogViewProps, any> {

  private iFrameRef = createRef<HTMLIFrameElement>();
  private handleMessageReceivedBound = this.handleMessageReceived.bind(this);
  private handleIFrameLoadedBound = this.handleIFrameLoaded.bind(this);

  public componentDidMount(): void {
    const iframe = this.iFrameRef.current;
    if (iframe) {
      iframe.addEventListener('load', this.handleIFrameLoadedBound);
    }

    window.addEventListener('message', this.handleMessageReceivedBound);
  }

  public componentWillUnmount(): void {
    const iframe = this.iFrameRef.current;
    if (iframe) {
      iframe.removeEventListener('load', this.handleIFrameLoadedBound);
    }

    window.removeEventListener('message', this.handleMessageReceivedBound);
  }

  public componentDidUpdate(prevProps: StartDialogViewProps): void {
    if (this.props.identity.token !== prevProps.identity.token) {
      this.handleIdentityChanged(this.props.identity);
    }
  }

  public render(): JSX.Element {
    return (
      <iframe
        className={`start-dialog-view__iframe ${this.props.className}`}
        ref={this.iFrameRef}
        title={this.props.config.title}
        src={this.props.config.url}
        frameBorder='0'
        style={this.props.iFrameStyle}
      />
    );
  }

  private handleIFrameLoaded(): void {
    const iFrame = this.iFrameRef.current;
    if (!iFrame || !iFrame.contentWindow) {
      return;
    }

    const message: DisplayStartDialogMessage = {
      type: StartDialogMessageType.DisplayStartDialog,
      configuration: this.props.config,
      identity: this.props.identity,
      payload: this.props.payload,
    };

    iFrame.contentWindow.postMessage(message, this.getIFrameOrigin());
  }

  private handleMessageReceived(event: MessageEvent): void {
    const message = event.data;

    if (isCloseStartDialogMessage(message)) {
      this.props.navigate('/');

    } else if (isStartProcessStartDialogMessage(message)) {
      const returnToStartDialogPayload = { returnToStartDialog: this.props.startDialogId };
      const payload = Object.assign(returnToStartDialogPayload, message.payload);
      const encodedPayload = Base64.encode(JSON.stringify(payload));

      const pathname = `/start/${message.processModelId}/${encodedPayload}`;
      const url = new URL(pathname, window.location.origin);
      if (message.startEventId) {
        url.searchParams.append('startEventId', message.startEventId);
      }
      if (message.correlationId) {
        url.searchParams.append('correlationId', message.correlationId);
      }

      this.props.navigate(url.href.replace(url.origin, ''));
    } else if (isOpenStartDialogMessage(message)) {
      const encodedPayload = typeof message.payload === 'object' && message.payload != null ? Base64.encode(JSON.stringify(message.payload)) : undefined;

      const pathname = `/startdialog/${message.startDialogId}/${encodedPayload ?? ''}`;
      const url = new URL(pathname, window.location.origin);

      this.props.navigate(url.href.replace(url.origin, ''));
    }
  }

  private handleIdentityChanged(newIdentity: Identity): void {
    const iFrame = this.iFrameRef.current;
    if (!iFrame || !iFrame.contentWindow) {
      return;
    }

    const message: UpdateIdentityStartDialogMessage = {
      type: StartDialogMessageType.UpdateIdentity,
      identity: newIdentity,
    };

    iFrame.contentWindow.postMessage(message, this.getIFrameOrigin());
  }

  private getIFrameOrigin(): string {
    return new URL(this.props.config.url).origin;
  }

}

export function StartDialogViewWithRouter(props: StartDialogViewWithRouterProps): JSX.Element | null {
  const { startDialogId , payload: encodedPayload} = useParams<StartDialogViewParameters>();
  const { push } = useHistory();
  const { identity } = useIdentity();

  const startDialogConfig = props.config[startDialogId];

  let payloadError: Error;
  let decodedPayload: Record<string, unknown> | undefined;
  if (encodedPayload) {
    try {
      decodedPayload = JSON.parse(Base64.decode(encodedPayload));
    } catch (decodeError) {
      payloadError = decodeError;
    }
  }

  const content = (): JSX.Element | null => {
    if (payloadError) {
      return <ErrorRenderer error={payloadError} />;
    }

    if (!startDialogId || !startDialogConfig) {
      return <Alert variant="danger">
        <Trans
          i18nKey="StartDialogView.StartDialogNotFound"
          values={{
            startDialogId: startDialogId,
          }}
          components={{
            code: <code/>,
          }}
        ></Trans>
      </Alert>
    }

    if (!identity) {
      return null;
    }

    const key = `${startDialogId}_${encodedPayload}`
    return (
      <div className={`start-dialog-view start-dialog-view--${startDialogId.trim().replaceAll(' ', '-')}`}>
        <div className="start-dialog-view__content">
          <StartDialogView
            key={key}
            startDialogId={startDialogId}
            config={startDialogConfig}
            navigate={push}
            identity={identity}
            payload={decodedPayload}
          />
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <LayoutSidebar visible={props.sidebarVisible} hideSidebar={props.hideSidebar} logo={props.logo} />
      <LayoutContent>
        <BackToHomepage />

        {content()}
      </LayoutContent>
    </Layout>
  );
}

export function StartDialogHomepage(props: StartDialogHomepageProps): JSX.Element {
  const { push } = useHistory();
  const { identity } = useIdentity();

  const [startDialogConfigs, setStartDialogConfigs] = useState<StartDialogsConfig | null>(null);

  useEffect(() => {
    getAllowedStartDialogs(props.config, props.authService)
      .then((startDialogs) => setStartDialogConfigs(startDialogs));
  }, [props.config]);

  const startDialogId = props.startDialogId;
  const startDialogConfig = startDialogConfigs ? startDialogConfigs[startDialogId] : null;

  const renderContent = (): JSX.Element | null => {
    if (!startDialogConfigs || !identity) {
      return null;
    }

    if (!startDialogId || !startDialogConfig) {
      return (
        <LayoutContent>
          <Alert variant="danger" style={{marginTop: '2rem'}}>
            <Trans
              i18nKey="StartDialogView.StartDialogNotFound"
              values={{
                startDialogId: startDialogId,
              }}
              components={{
                code: <code/>,
              }}
            ></Trans>
          </Alert>
        </LayoutContent>
      )
    }

    return <StartDialogView
      className={`start-dialog-view--${startDialogId.trim().replaceAll(' ', '-')}`}
      startDialogId={startDialogId}
      config={startDialogConfig}
      navigate={push}
      identity={identity}
      iFrameStyle={{width: '100%', gridArea: 'content'}}
    />;
  }

  return (
    <Layout>
      <LayoutSidebar activeNav="homepage" visible={props.sidebarVisible} hideSidebar={props.hideSidebar} logo={props.logo} />
      {renderContent()}
    </Layout>
  );
}
