import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import jwtDecode from 'jwt-decode';

import { AuthorityConfig } from '@atlas-engine-contrib/atlas-ui_contracts';

import { IAuthService, IdentityWithEmail, ProcessSigninResponseResult } from './IAuthService';

import { AtlasAuthorityUnreachableError, AuthorityUrlNotDefinedError, isConnectionError } from './InternalTypes';

import { OnIdentityChangedCallback } from '.';

export class AuthService implements IAuthService {
  private userManager!: UserManager;
  private isLoggedInFlag: boolean | undefined;
  private onIdentityChangedCallbacks = new Set<OnIdentityChangedCallback>();

  private constructor() {};

  public static async create(oauth2Config: AuthorityConfig): Promise<AuthService> {
    const authService = new AuthService();
    await authService.initialize(oauth2Config);

    return authService;
  }

  private async initialize(oauth2Config: AuthorityConfig): Promise<void> {

    const stateStorage = new WebStorageStateStore({store: window.sessionStorage});
    const allKeys = await stateStorage.getAllKeys();
    const activeSession = allKeys.find((key) => key.endsWith(`:${oauth2Config.clientId}`));

    if (oauth2Config.authority == null && activeSession == null) {
      throw new AuthorityUrlNotDefinedError();
    }

    const authorityUrlFromActiveSession = activeSession?.replace('user:', '').replace(`:${oauth2Config.clientId}`, '');
    const authority = oauth2Config.authority ?? authorityUrlFromActiveSession;
    /* eslint-disable @typescript-eslint/naming-convention */
    this.userManager = new UserManager({
      authority: authority,
      client_id: oauth2Config.clientId,
      redirect_uri: `${oauth2Config.redirectBasePath}/signin-oidc`,
      silent_redirect_uri: `${oauth2Config.redirectBasePath}/signin-oidc`,
      post_logout_redirect_uri: `${oauth2Config.redirectBasePath}/signout-oidc`,
      loadUserInfo: false,
      response_type: oauth2Config.responseType,
      scope: `openid profile email ${oauth2Config.scopes}`,
    });
    /* eslint-enable @typescript-eslint/naming-convention */

    this.userManager.startSilentRenew();
    this.userManager.events.addUserLoaded(this.handleUserLoadedEvent.bind(this));
    this.userManager.events.addUserUnloaded(this.handleUserUnloadedEvent.bind(this));
  }

  public async login(targetRoutingState?: unknown): Promise<void> {
    try {
      if (targetRoutingState != null) {
        localStorage.setItem('targetRoutingState', JSON.stringify(targetRoutingState));
      }

      await this.userManager.signinRedirect();
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  public logout(): void {
    try {
      this.userManager.signoutRedirect();
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  public async processSigninResponse(): Promise<ProcessSigninResponseResult> {
    try {
      const user = await this.userManager.signinCallback();
      this.isLoggedInFlag = true;
      this.userManager.events.load(user);

      const identity = this.mapUserToIdentity(user);
      let parsedTargetRoutingState;

      const targetRoutingState = localStorage.getItem('targetRoutingState');
      if (targetRoutingState) {
        localStorage.removeItem('targetRoutingState');
        parsedTargetRoutingState = JSON.parse(targetRoutingState);
      }

      return {
        identity: identity,
        targetRoute: parsedTargetRoutingState ?? '/',
      };
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  public async processSignoutResponse(): Promise<void> {
    try {
      await this.userManager.signoutCallback();
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  public async detectLoggedInFlag(): Promise<boolean> {
    if (this.isLoggedInFlag !== undefined) {
      return this.isLoggedInFlag;
    }
    try {
      const user = await this.userManager.getUser();
      if (!user || user.expired) {
        return false;
      }
      this.isLoggedInFlag = true;
    } catch (error) {
      this.isLoggedInFlag = false;
    }
    return this.isLoggedInFlag;
  }

  public async getIdentity(): Promise<IdentityWithEmail> {
    try {
      const user = await this.userManager.getUser();

      if (!user) {
        throw new Error('not logged in');
      }

      return this.mapUserToIdentity(user);
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  public isLoggedIn(): boolean {
    return !!this.isLoggedInFlag;
  }

  public async hasClaim(claim: string): Promise<boolean> {
    try {
      const user = await this.userManager.getUser();

      if (!user || !user.access_token || user.access_token === '') {
        return false;
      }

      const decodedAccessToken = jwtDecode<Record<string, unknown>>(user.access_token);

      if (!decodedAccessToken) {
        return false;
      }

      return decodedAccessToken[claim] != undefined;
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  public onIdentityChanged(callback: OnIdentityChangedCallback): void {
    this.onIdentityChangedCallbacks.add(callback);
  }

  public removeOnIdentityChanged(callback: OnIdentityChangedCallback): void {
    this.onIdentityChangedCallbacks.delete(callback);
  }

  private handleUserLoadedEvent(user: User): void {
    const identity = this.mapUserToIdentity(user);

    for (const callback of this.onIdentityChangedCallbacks) {
      callback(identity);
    }
  }

  private handleUserUnloadedEvent(): void {
    for (const callback of this.onIdentityChangedCallbacks) {
      callback(null);
    }
  }

  private handleAtlasAuthorityRequestError(error: any): void {
    if (isConnectionError(error)) {
      throw new AtlasAuthorityUnreachableError(error.code);
    }

    throw error;
  }

  private mapUserToIdentity(user: User): IdentityWithEmail {
    return {
      token: user.access_token,
      userId: user.profile.sub,
      email: user.profile.email,
    };
  }
}
