/* eslint-disable max-len */
import { Alert } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import { ForbiddenError, isAtlasEngineError } from '@atlas-engine/atlas_engine_sdk';

import { AtlasAuthorityUnreachableError, AtlasEngineUnreachableError, ConfigurationError } from '../../lib/index';

type ErrorRendererProps = {
  error: Error;
}

export function ErrorRenderer(props: ErrorRendererProps): JSX.Element {
  const { t } = useTranslation();
  const error = props.error;

  if (isAtlasEngineError(error)) {
    if (error instanceof ForbiddenError && error?.additionalInformation?.claim) {

      const missingClaim = error?.additionalInformation?.claim;

      return (
        <Alert variant='danger'>
          <Alert.Heading>{t('ErrorRenderer.NoPermissions')}</Alert.Heading>
          <Trans i18nKey="ErrorRenderer.MissingClaim"
            values={{ missingClaim: missingClaim }}
            components={{ code: <code/> }}/>
        </Alert>
      );
    }

    if (error instanceof AtlasAuthorityUnreachableError) {
      return (
        <Alert variant='danger'>
          <Alert.Heading>{t('ErrorRenderer.AtlasAuthorityUnreachableError')}</Alert.Heading>
          <pre className="alert-danger">{error.message.toString()}</pre>
        </Alert>
      );
    }

    if (error instanceof AtlasEngineUnreachableError) {

      return (
        <Alert variant='danger'>
          <Alert.Heading>{t('ErrorRenderer.AtlasEngineUnreachableError')}</Alert.Heading>
          <pre className="alert-danger">{error.message.toString()}</pre>
        </Alert>
      );
    }

    return (
      <Alert variant='danger'>
        <Alert.Heading>{error.name}: {error.message}</Alert.Heading>
        <pre>{JSON.stringify(error, null, '  ')}</pre>
      </Alert>
    );
  }

  if (error instanceof ConfigurationError) {
    return (
      <Alert variant='danger'>
        <Alert.Heading>{t('ErrorRenderer.ConfigurationError')}</Alert.Heading>
        <pre className="alert-danger">{error.message.toString()}</pre>
      </Alert>
    );
  }

  return (
    <Alert variant='danger'>
      <Alert.Heading>{t('ErrorRenderer.UnknownError')}</Alert.Heading>
      <pre className="alert-danger">{error.toString()}</pre>
    </Alert>
  );
}
