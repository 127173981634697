import { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { StartableGroupConfig } from '@atlas-engine-contrib/atlas-ui_contracts';

import { AtlasEngineService } from '../../../lib';
import { ErrorRenderer } from '../ErrorRenderer';
import { ProcessModel } from './ProcessModel';
import { Startable } from './StartableList';
import { StartDialog } from './StartDialog';
import { WithTranslation, withTranslation } from 'react-i18next';

type StartableGroupProps = {
  group: StartableGroupConfig;
  startables: Array<Startable>;
  atlasEngineService: AtlasEngineService;
  searchFilter?: string;
} & RouteComponentProps & WithTranslation;

type StartableGroupState = {
  lastProcessStartError?: Error;
};

export class StartableGroupComponent extends Component<StartableGroupProps, StartableGroupState> {

  constructor(props: StartableGroupProps) {
    super(props);

    this.state = {};
  }

  public render(): JSX.Element {
    const { group, startables } = this.props;
    const safeGroupId = group.id.trim().replaceAll(' ', '-');
    const startableComponents = startables.map(this.mapStartableToComponent.bind(this));

    return (
      <Fragment>
        {this.state.lastProcessStartError && <ErrorRenderer error={this.state.lastProcessStartError}/>}
        <div className={`startable-group startable-group--${safeGroupId}`}>
          <div className="startable-group__title">{this.props.t(`StartableGroups.${group.id}`, {defaultValue: group.title})}</div>
          <div className="startable-group__startables">
            {startableComponents}
          </div>
        </div>
      </Fragment>
    );
  }

  private mapStartableToComponent(startable: Startable): JSX.Element {
    switch (startable.type) {
      case 'processModel':
        const processModelTitle = this.props.t(`Startables.${startable.config.id}.Title`, {defaultValue: startable.config.title});
        const processModelBody = this.props.t(`Startables.${startable.config.id}.Body`, {defaultValue: startable.config.body});

        const translatedStartButtonTitles: {[startEventId: string]: string} = {};

        Object.entries(startable.config.startButtonTitles).forEach((entry, index) => {
          const key = entry[0];
          const value = entry[1];

          const translatedValue = this.props.t(`Startables.${startable.config.id}.StartButtonTitles.${key}`, {defaultValue: value});

          const noTranslationForValue = translatedValue === value;
          if (index === 0 && noTranslationForValue) {
            translatedStartButtonTitles[key] = this.props.t(`Startables.${startable.config.id}.StartButtonTitle`, {defaultValue: value});
            return;
          }

          translatedStartButtonTitles[key] = translatedValue;
        })

        return (
          <ProcessModel
            key={startable.config.id}
            id={startable.config.id}
            onStart={this.startProcessAndNavigateToProcess.bind(this)}
            startButtonTitles={translatedStartButtonTitles}
            title={processModelTitle}
            body={processModelBody}
            searchFilter={this.props.searchFilter}
          />
        );
      case 'startDialog':
        const startDialogTitle = this.props.t(`Startables.${startable.config.id}.Title`, {defaultValue: startable.config.title});
        const startDialogBody = this.props.t(`Startables.${startable.config.id}.Body`, {defaultValue: startable.config.body});
        const startDialogStartButtonTitle = this.props.t(`Startables.${startable.config.id}.StartButtonTitle`, {defaultValue: startable.config.startButtonTitle});

        return (
          <StartDialog
            key={startable.config.id}
            body={startDialogBody}
            onStart={this.navigateToStartDialog.bind(this)}
            startButtonTitle={startDialogStartButtonTitle}
            startDialogId={startable.config.id}
            title={startDialogTitle}
            url={startable.config.url}
            searchFilter={this.props.searchFilter}
          />
        );
      default:
        throw new Error('Unknown startable type encountered');
    }
  }

  private async startProcessAndNavigateToProcess(processModelId: string, startEventId?: string): Promise<void> {
    try {
      const processInstance = await this.props.atlasEngineService.startProcessInstance(processModelId, undefined, startEventId);
      this.props.history.push(`/correlation/${processInstance.correlationId}`);
    } catch (error) {
      this.setState({ lastProcessStartError: error });
    }
  }

  private navigateToStartDialog(startDialogId: string): void {
    this.props.history.push(`/startdialog/${startDialogId}`);
  }

}

export const StartableGroup = withTranslation()(withRouter(StartableGroupComponent));
