import { Fragment } from 'react';

import { RenderHookProps } from '@atlas-engine-contrib/atlas-ui_contracts';

import { useExtensionService } from '../context/RenderHookContext';

type RenderHooksProps = {
  id: string;
};

export function RenderHooks(props: RenderHooksProps): JSX.Element | null {
  const extensionService = useExtensionService();
  if (extensionService == null) {
    return null;
  }

  const portal = extensionService.getPortal();
  const genericRenderHookProps: RenderHookProps = { portal: portal, attachedRenderHookId: props.id };
  const registeredReactComponents = portal.getRenderHooks(props.id);

  if (registeredReactComponents == null) {
    return null;
  }

  return <Fragment>
    {registeredReactComponents.map((RegisteredComponent, index: number) =>
      <RegisteredComponent key={`${props.id}__${index}`} {...genericRenderHookProps} />)}
  </Fragment>;
}
