import { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  ProcessModelConfig,
  StartDialogConfig,
  StartDialogsConfig,
  StartableGroupConfig,
} from '@atlas-engine-contrib/atlas-ui_contracts';

import {
  AtlasEngineService,
  matchesSearchFilter,
} from '../../../lib';
import { StartableGroup } from './StartableGroup';

type StartableListProps = {
  processModels: Array<ProcessModelConfig>;
  startDialogs: StartDialogsConfig;
  startablesOrder?: Array<string>;
  startableGroups?: Array<StartableGroupConfig>;
  atlasEngineService: AtlasEngineService;
  searchFilter: string;
  maxVisibleStartables?: number;
  showGroups?: boolean;
} & RouteComponentProps & WithTranslation;

type ProcessModelStartable = {
  type: 'processModel';
  config: ProcessModelConfig;
};

type StartDialogStartable = {
  type: 'startDialog';
  config: StartDialogConfig & {id: string};
};

export type Startable = ProcessModelStartable | StartDialogStartable;

type GroupedStartables = {[groupId: string]: Array<Startable>};

type GroupStartablesResult = {
  groups: GroupedStartables;
  ungrouped: Array<Startable>;
};

export class StartableListComponent extends Component<StartableListProps> {

  // "withRouter" breaks TypeScript's support for "defaultProps". That's why "showGroups" is currently marked as optional.
  public static defaultProps = {
    showGroups: true,
  };

  public render(): JSX.Element {
    const { t, searchFilter, maxVisibleStartables, showGroups } = this.props;
    let startables = this.getStartables();

    const duplicateIds = this.findDuplicateIds(startables);
    let duplicateIdsAlert = null;

    if (duplicateIds.length === 0) {
      startables = startables.sort(this.sortByStartablesOrder.bind(this));
    } else {
      const message = t('StartableList.DuplicateIdsFound', { duplicateIds: duplicateIds.join(', ') });
      duplicateIdsAlert = <Alert variant='danger'>{message}</Alert>;
    }

    return (
      <Fragment>
        {duplicateIdsAlert}
        <div className="startable-list">
          {this.renderStartables(startables, searchFilter, showGroups, maxVisibleStartables)}
        </div>
      </Fragment>
    );
  }

  private renderStartables(
    startables: Array<Startable>,
    searchFilter: string,
    showGroups?: boolean,
    maxVisibleStartables?: number,
  ): JSX.Element | Array<JSX.Element> {
    let filteredStartables = this.filterBySearchFilter(startables, searchFilter);

    if (maxVisibleStartables != null && maxVisibleStartables > 0) {
      const filteredStartablesCopy = [...filteredStartables];
      filteredStartables = filteredStartablesCopy.splice(0, maxVisibleStartables);
    }

    if (filteredStartables.length === 0 && searchFilter != null && searchFilter.trim() !== '') {
      return <Alert variant='danger'>{this.props.t('StartableList.NoProcessesFound')}</Alert>;
    }

    if (!showGroups) {
      return this.renderUngroupedStartables(filteredStartables);
    }

    const groupStartablesResult = this.groupStartables(filteredStartables);
    const groupedStartableComponents = this.renderGroupedStartables(groupStartablesResult.groups);
    const ungroupedStartableComponents = this.renderUngroupedStartables(groupStartablesResult.ungrouped);

    return groupedStartableComponents.concat(ungroupedStartableComponents);
  }

  private renderGroupedStartables(groupedStartables: GroupedStartables): Array<JSX.Element> {
    if (!this.props.startableGroups) {
      return [];
    }

    return this.props.startableGroups
      .map((group) => {
        const startablesInGroup = groupedStartables[group.id];

        if (!startablesInGroup || startablesInGroup.length === 0) {
          return null;
        }

        return (
          <StartableGroup
            key={group.id}
            atlasEngineService={this.props.atlasEngineService}
            group={group}
            startables={startablesInGroup}
            searchFilter={this.props.searchFilter}
          />
        );
      })
      .filter(notEmpty);
  }

  private renderUngroupedStartables(startables: Array<Startable>): JSX.Element {
    const group = {
      id: 'ungrouped',
      title: '',
    };

    return (
      <StartableGroup
        key={group.id}
        atlasEngineService={this.props.atlasEngineService}
        group={group}
        startables={startables}
        searchFilter={this.props.searchFilter}
      />
    );
  }

  private getStartables(): Array<Startable> {
    const processModelStartables = this.props.processModels.map((processModelConfig): Startable => {
      return {
        type: 'processModel',
        config: processModelConfig,
      };
    });

    const startDialogStartables = Object
      .entries(this.props.startDialogs)
      .map(([startDialogId, startDialogConfig]) => {
        return Object.assign({ id: startDialogId }, startDialogConfig);
      })
      .map((startDialogConfig): Startable => {
        return {
          type: 'startDialog',
          config: startDialogConfig,
        };
      });

    return processModelStartables.concat(startDialogStartables);
  }

  private groupStartables(startables: Array<Startable>): GroupStartablesResult {
    const result: GroupStartablesResult = {
      groups: {},
      ungrouped: [],
    };

    for (const startable of startables) {
      if (!startable.config.groupId) {
        result.ungrouped.push(startable);
        continue;
      }

      const groupId = startable.config.groupId;
      const groupIsConfigured = this.props.startableGroups?.some((startableGroup) => startableGroup.id === groupId);

      if (!groupIsConfigured) {
        result.ungrouped.push(startable);
        continue;
      }

      const groupAlreadyExists = Object.prototype.hasOwnProperty.call(result.groups, groupId);

      if (groupAlreadyExists) {
        result.groups[groupId].push(startable);
      } else {
        result.groups[groupId] = [startable];
      }
    }

    return result;
  }

  private findDuplicateIds(startables: Array<Startable>): Array<string> {
    const startableIds = startables.map((startable) => startable.config.id);
    const duplicateStartableIds = startableIds.filter((startableId, index) => startableIds.indexOf(startableId) !== index);

    return [...new Set(duplicateStartableIds)];
  }

  private sortByStartablesOrder(firstStartable: Startable, secondStartable: Startable): number {
    const startablesOrder = this.props.startablesOrder ?? [];
    const firstStartableInOrderList = startablesOrder.includes(firstStartable.config.id);
    const secondStartableInOrderList = startablesOrder.includes(secondStartable.config.id);

    if (firstStartableInOrderList && !secondStartableInOrderList) {
      return -1;
    }

    if (!firstStartableInOrderList && secondStartableInOrderList) {
      return 1;
    }

    if (firstStartableInOrderList && secondStartableInOrderList) {
      const firstStartableOrderIndex = startablesOrder.indexOf(firstStartable.config.id);
      const secondStartableOrderIndex = startablesOrder.indexOf(secondStartable.config.id);

      return firstStartableOrderIndex - secondStartableOrderIndex;
    }

    const compareOptions = { usage: 'sort', sensitivity: 'base' }; // ignore case differences
    return firstStartable.config.title.localeCompare(secondStartable.config.title, undefined, compareOptions);
  }

  private filterBySearchFilter(startables: Array<Startable>, searchFilter: string): Array<Startable> {
    if (searchFilter.trim() === '') {
      return startables;
    }

    return startables.filter((startable) => {
      switch (startable.type) {
        case 'processModel':
          return this.processModelMatchesSearchFilter(startable.config, searchFilter);
        case 'startDialog':
          return this.startDialogMatchesSearchFilter(startable.config, searchFilter);
        default:
          throw new Error('Unknown startable type encountered');
      }
    });
  }

  private processModelMatchesSearchFilter(processModel: ProcessModelConfig, searchFilter: string): boolean {
    const startButtonTitles = Object
      .values(processModel.startButtonTitles)
      .join(' ');

    const processModelAsSearchableString = `${processModel.title} ${processModel.body} ${startButtonTitles}`;

    return matchesSearchFilter(processModelAsSearchableString, searchFilter);
  }

  private startDialogMatchesSearchFilter(startDialog: StartDialogConfig, searchFilter: string): boolean {
    const startDialogAsSearchableString = `${startDialog.title} ${startDialog.body} ${startDialog.startButtonTitle}`;

    return matchesSearchFilter(startDialogAsSearchableString, searchFilter);
  }

}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value != null;
}

export const StartableList = withTranslation()(withRouter(StartableListComponent));
