export function matchesSearchFilter(text: string, searchFilter: string): boolean {
  const searchText = text.toLowerCase();
  const searchWords = searchFilter.trim().toLowerCase()
    .split(/\s+/);

  if (searchWords.length === 0) {
    return true;
  }

  return searchWords.every((word) => searchText?.includes(word));
}
