import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { IAuthService, IdentityWithEmail } from '../../lib';

type IdentityProviderProps = {
  authService?: IAuthService;
};

type IdentityContextValue = {
  identity: IdentityWithEmail | null;
  setIdentity: Dispatch<SetStateAction<IdentityWithEmail | null>>;
};

const initialValue = {
  identity: null,
  setIdentity: (): void => {},
};

const IdentityContext = createContext<IdentityContextValue>(initialValue);

export function IdentityProvider(props: PropsWithChildren<IdentityProviderProps>): JSX.Element {
  const { authService, children } = props;
  const [identity, setIdentity] = useState<IdentityWithEmail | null>(null);

  useEffect(() => {
    authService?.getIdentity()
      .then((_identity) => {
        setIdentity(_identity);
      })
      .catch((error) => {
        if (error.message !== 'not logged in') {
          throw error;
        }
      });

    const onIdentityChangedCallback = (_identity: IdentityWithEmail | null): void => {
      setIdentity(_identity);
    }

    authService?.onIdentityChanged(onIdentityChangedCallback);

    return (): void => {
      authService?.removeOnIdentityChanged(onIdentityChangedCallback);
    }
  }, [authService]);

  // Avoid triggering a re-render of components consuming this provider when
  // neither "identity" nor "setIdentity" changed.
  const identityContextValue = useMemo(() => {
    return {
      identity: identity,
      setIdentity: setIdentity,
    };
  }, [identity, setIdentity]);

  return (
    <IdentityContext.Provider value={identityContextValue}>
      {children}
    </IdentityContext.Provider>
  );
}

export function useIdentity(): IdentityContextValue {
  return useContext(IdentityContext);
}

export type WithIdentity = IdentityContextValue;

export function withIdentity<TProps extends WithIdentity>(Component: React.ComponentType<TProps>): any {
  const ComponentWithIdentity = (props: Omit<TProps, keyof WithIdentity>): any => {
    const { identity, setIdentity } = useIdentity();

    // Unfortunately, the type assertion is necessary due to a likely bug in TypeScript
    // https://github.com/Microsoft/TypeScript/issues/28938
    return <Component {...props as TProps} identity={identity} setIdentity={setIdentity} />;
  };

  const componentName = Component.displayName ?? Component.name ?? 'Component';
  ComponentWithIdentity.displayName = `WithIdentity(${componentName})`;

  return ComponentWithIdentity;
}
