import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Base64 } from 'js-base64';

import { AtlasEngineService } from '../../../lib/index';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { Layout, LayoutContent, LayoutSidebar } from '../../Layout';
import { BackToHomepage } from '../../components/BackToHomepage';
import { GenericViewProps } from '../../GenericViewProps';
import { DelayedRenderer } from '../../components/DelayedRenderer';

export type StartProcessViaUrlViewProps = GenericViewProps & {
  atlasEngineService: AtlasEngineService;
};

type StartProcessViaUrlParameters = {
  processModelId: string;
  payload: any;
}

export function StartProcessViaUrlView(props: StartProcessViaUrlViewProps): JSX.Element {
  const { t } = useTranslation();

  const {
    processModelId,
    payload: encodedPayload,
  } = useParams<StartProcessViaUrlParameters>();

  const queryParams = useQuery();
  const startEventId = queryParams.get('startEventId') ?? undefined;
  const correlationId = queryParams.get('correlationId') ?? undefined;

  const history = useHistory();
  const [
    lastError,
    setLastError,
  ] = useState<Error | null>(null);

  useEffect(() => {
    if (!processModelId) {
      return;
    }

    let payload;
    if (encodedPayload) {
      try {
        payload = JSON.parse(Base64.decode(encodedPayload));
      } catch (error) {
        setLastError(error);
        return;
      }
    }

    props.atlasEngineService.startProcessInstance(processModelId, payload, startEventId, correlationId)
      .then((result) => {
        history.replace(`/correlation/${result.correlationId}`);
      })
      .catch((error) => {
        setLastError(error);
      });

  }, [
    processModelId,
    encodedPayload,
    props.atlasEngineService,
    history,
  ]);

  return (
    <Layout>
      <LayoutSidebar visible={props.sidebarVisible} hideSidebar={props.hideSidebar} logo={props.logo} />
      <LayoutContent>
        <BackToHomepage />
        {lastError && <ErrorRenderer error={lastError} />}
        <DelayedRenderer>
          {!lastError && <span className="start-process-via-url-view">{t('StartProcessViaURL.ProcessIsStarting')}</span>}
        </DelayedRenderer>
      </LayoutContent>
    </Layout>
  );
}

function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

